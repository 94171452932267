  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-1-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>L'expert construction</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Qu’est-ce qu’un Expert construction ?</h1>

<p>L’Expert technique construction & bâtiment est un professionnel indépendant du bâtiment.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="L'expert construction" />
</div>

<p>Il joue un rôle multiple auprès de son client. </p>
<p>Il le conseille et met à disposition ses compétences techniques en construction et en gestion de sinistre, afin de l’accompagner dans toute démarche de résolution de problématiques de construction.</p>

<p>Il possède donc à ce titre, de solides compétences en techniques de la construction, pathologies de la construction et en ingénierie structurelle.</p>

<h2>Domaines d’application de l’expert construction</h2>

<p>L’expert construction intervient généralement pour :</p>
<ul><li>le besoin d’un avis technique d’un professionnel certifié (avis technique général sur ouvrage)</li>
<li>suite à un sinistre sur un ouvrage,</li>
<li>en conseiller technique de partie, lors d’une contre-expertise,</li></ul>


<p>Par ses compétences, l’Expert construction saura vous conseiller sur les mesures conservatoires et les solutions techniques les plus adaptées, afin de préserver un ouvrage construit dans un état structurel robuste et pérenne.</p>

<p>Ses compétences sont reconnues auprès de ses pairs et auprès d’un tribunal.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert construction</a>
    </div>
</div>                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expert-construction/' className='active'>L'expert construction</a></li><li><a href='/expertise-construction/expert-construction/role/' >Role</a></li><li><a href='/expertise-construction/expert-construction/objectifs/' >Objectifs</a></li><li><a href='/expertise-construction/expert-construction/mission/' >Déroulement de la mission</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details